<template>
  <div class="box">
    <!-- 面包屑导航区 -->
    <el-breadcrumb>
      <el-breadcrumb-item>骑手管理</el-breadcrumb-item>
      <el-breadcrumb-item class="pointer" :to="{ path: '/riderlist' }"
        >骑手列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>骑手详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="detail">
      <el-form label-width="120px" class="detail-form" size="medium">
        <el-row>
          <el-col :span="12">
            <el-form-item label="配送员姓名" prop="name">
              <el-input
                v-model="detailForm.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="detailForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行卡号" prop="card1">
              <el-input
                v-model="detailForm.card1"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支行名称" prop="card2">
              <el-input
                v-model="detailForm.card2"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌号" prop="card3">
              <el-input
                v-model="detailForm.card3"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="当前状态" prop="status">
              <el-select class="input" v-model="detailForm.status" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配送范围" prop="areaName">
              <el-input
                v-model="detailForm.areaName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平台抽成比例" prop="dd4">
              <el-input
                v-model="detailForm.dd4"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="配送员头像" prop="userImg">
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleAvatarImg"
                >
                <img v-if="detailForm.userImg" :src="detailForm.userImg" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证" prop="idCardImgFront">
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleIdcardImg"
                >
                <img v-if="detailForm.idCardImgFront" :src="detailForm.idCardImgFront" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆照片" prop="idCardImgReverse">
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleCarImg"
                >
                <img v-if="detailForm.idCardImgReverse" :src="detailForm.idCardImgReverse" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="健康证" prop="licenceImg">
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleHealthcardImg"
                >
                <img v-if="detailForm.licenceImg" :src="detailForm.licenceImg" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="display: flex;justify-content: center;margin-top: 28px;">
            <el-button type="primary" @click="submit"
              >提交</el-button
            >
            <el-button @click="$router.go(-1)">返回</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
data() {
  return {
      options: [
        {
          value: 1,
          label: "待审核",
        },
        {
          value: 2,
          label: "已通过",
        },
        {
          value: 3,
          label: "已拒绝",
        },
      ],
      detailForm: {
        userImg: '',
        idCardImgFront: '',
        idCardImgReverse: '',
        licenceImg: '',
      },
      showDialog:false,
      businessdetail:{},
      id:'',
  };
},
created(){
  var that = this;
  if(this.$route.query.id){
    this.id = this.$route.query.id
    this.$http.post('/rider/queryOne',{
      id:this.id
    }).then(response => {
      that.detailForm = response.data.data;
    })
  }
},
mounted() {
  // this.getList ()
},
methods: {
  change(e){
    console.log(e);
  },
  back(){
    this.$router.push({path:'/riderlist'})
  },
  //获取数据
  getList () {
    var that = this;
    that.$http.post("/business/list", that.queryInfo).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
        that.total = response.data.data.count;
        that.queryInfo.currPage=response.data.data.currPage
        that.tableData = response.data.data.data;
      }
    });
  },
  // 配送员头像
  handleAvatarImg(res, file) {
    this.detailForm.userImg = file.response.data.url;
    console.log(file.response.data.url, '88888888',this.detailForm);
  },
  // 身份证
  handleIdcardImg(res, file) {
    this.detailForm.idCardImgFront = file.response.data.url;
    console.log(file.response.data.url, '88888888',this.detailForm);
  },
  // 车辆照片
  handleCarImg(res, file) {
    this.detailForm.idCardImgReverse = file.response.data.url;
    console.log(file.response.data.url, '88888888',this.detailForm);
  },
  // 健康证
  handleHealthcardImg(res, file) {
    this.detailForm.licenceImg = file.response.data.url;
    console.log(file.response.data.url, '88888888',this.detailForm);
  },
  submit(){
    var that = this;
    that.$http.post("/rider/save", that.detailForm).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
        that.$message.success('提交成功！')
        that.$router.go(-1)
      }
    });
  },
}
};
</script>

<style lang="less" scoped>
.box {
  .detail {
    /deep/.el-form-item__label{
      padding-right: 14px;
    }
      background-color: #fff;
      box-sizing: border-box;
      padding: 60px;
      .detail-top {
          padding: 5px;
          background-color: rgba(30, 152, 215, 0.14);
          font-size: 15px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          div {
              width: 150px;
              text-align: center;
          }
      }
      .detail-form{
        padding-right: 50px;
        .avatar-uploader {
          width: 200px;
          height: 200px;
        }

        .avatar-uploader ::v-deep .el-upload {
          //background-color: #fbfdff;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }

        .avatar-uploader-icon {
          border: 1px dashed #c0ccda;
          font-size: 28px;
          color: #8c939d;
          width: 148px;
          height: 148px;
          line-height: 148px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 99;
        }
      }
  }
  .tab {
      background-color: #fff;
      margin-top: 15px;
      padding: 15px
  }
}
.avatar {
    width: 178px;
    height: 178px;
    border: 1px solid #877b7b;
    border-radius: 6px;
    object-fit: scale-down;
  }
/deep/ .el-switch__label * {
  font-size: 12px;
}
</style>
